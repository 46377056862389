import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import UpButton from "../components/UpButton/UpButton"
import { theme } from "../theme/theme"
import "./index.css"

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    color: black;
    font-family: ${({ theme }) => theme.fonts.sansSerif} !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  body {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    width: 100vw;
    overflow-x:hidden;
    padding: 0 !important;
  }

  .MuiPickersDay-day {
    color: white;
  }

  .MuiPickersDay-dayDisabled{
    color: #737373;
  }
  .MuiTypography-root.MuiTypography-body1.MuiTypography-alignCenter {
    color: #fff !important;
  }

  .MuiPickersBasePicker-container {
    /* background-color: ${({ theme }) => theme.colors.darkBlue} */
    background-color: #000
  }

  .MuiPickersDay-daySelected, .MuiPickersCalendarHeader-iconButton, .MuiPickersDay-daySelected:hover {
    background-color: #3a4169;
  }
.MuiIconButton-label {
  color: inherit;
}

.MuiInputLabel-animated {
  color: #fff;
}
.MuiInput-underline:before {
  border-bottom: 1px solid #ffffff6b;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -12px) scale(0.75);
}

.MuiPickersCalendarHeader-dayLabel {
    color: white;
}

input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
	-webkit-text-fill-color: white;
	-webkit-box-shadow: 0 0 0px 1000px #020c1b inset;
	box-shadow: 0 0 0px 1000px #020c1b inset;
	transition: background-color 5000s ease-in-out 0s;
}
label {
  color: rgb(0 0 0) !important;
}

.tl-wrapper { transform: none !important;}

.MuiInput-underline:after {
  transform: scale(1);
  border-bottom: 2px solid #000;
}
`

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <main>{children}</main>
      <UpButton />
    </ThemeProvider>
  )
}

export default Layout
