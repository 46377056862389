export const theme = {
  colors: {
    darkBlue: "#dbdee2",
    grayBlue: "#cfcfcf",
    secondary: "#0194da",
    gray: "#272727",
    white: "#fff",
  },

  fonts: {
    serif: "'Playfair Display', serif",
    sansSerif: "'Poppins', sans-serif",
  },
}
