import React, { useCallback, useEffect } from "react"
import styled from "styled-components"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import { Button } from "@material-ui/core"
import smoothscroll from "smoothscroll-polyfill"

const Wrapper = styled(Button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0194da;
  opacity: 0.7;
  min-width: fit-content;
  width: 40px;
  height: 40px;
  display: none;
  border-radius: 50%;
  transition: opacity 0.15s linear;
  z-index: 999;
  box-shadow: 0px 2px 3px 1px #0000006b;
  &:hover {
    background-color: #0194da;
    opacity: 1 !important;
  }
  span {
    width: 20px;
  }

  svg {
    color: #fff !important;

    path {
      color: #fff !important;
    }
  }
`

const UpButton = () => {
  const handleScroll = () => {
    smoothscroll.polyfill()

    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const showUpButton = useCallback(() => {
    if (window.scrollY >= 900) {
      document.querySelector(".up-button").style.display = "flex"

      setTimeout(() => {
        document.querySelector(".up-button").style.opacity = 0.7
      }, 200)
    } else {
      document.querySelector(".up-button").style.display = "none"
      document.querySelector(".up-button").style.opacity = 0
    }
  }, [])
  useEffect(() => {
    window.addEventListener("scroll", showUpButton)

    return () => {
      window.removeEventListener("scroll", showUpButton)
    }
  }, [showUpButton])

  return (
    <Wrapper onClick={handleScroll} className="up-button">
      <ArrowUpwardIcon />
    </Wrapper>
  )
}

export default UpButton
