// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offer-banners-js": () => import("./../src/pages/offer/banners.js" /* webpackChunkName: "component---src-pages-offer-banners-js" */),
  "component---src-pages-offer-flights-js": () => import("./../src/pages/offer/flights.js" /* webpackChunkName: "component---src-pages-offer-flights-js" */),
  "component---src-pages-offer-selling-js": () => import("./../src/pages/offer/selling.js" /* webpackChunkName: "component---src-pages-offer-selling-js" */),
  "component---src-pages-offer-skydiving-js": () => import("./../src/pages/offer/skydiving.js" /* webpackChunkName: "component---src-pages-offer-skydiving-js" */),
  "component---src-pages-where-we-fly-js": () => import("./../src/pages/where-we-fly.js" /* webpackChunkName: "component---src-pages-where-we-fly-js" */)
}

